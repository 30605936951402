import i18n from 'services/i18n.service';

export const VERSION = process.env.REACT_APP_VERSION;
export const GIT_SHA = process.env.REACT_APP_GIT_SHA;

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const JOB_LISTING_BASE_URL = process.env.REACT_APP_JOB_LISTING_BASE_URL;

export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_CALLBACK_URL = process.env.REACT_APP_AUTH0_CALLBACK_URL;
export const AUTH0_CONTAINER_ID = 'login-container';

// https://auth0.com/docs/api-auth/tutorials/adoption/scope-custom-claims#custom-claims
export const AUTH0_CUSTOM_CLAIMS_NAMESPACE = 'https://expand.mindmatch.ai';

export const TYPEFORM_COMPANY_PROFILE_URL = process.env.REACT_APP_TYPEFORM_COMPANY_PROFILE_URL;
export const TYPEFORM_JOB_DETAILS_URL = process.env.REACT_APP_TYPEFORM_JOB_DETAILS_URL;
export const TYPEFORM_BUY_CREDITS_URL = process.env.REACT_APP_TYPEFORM_BUY_CREDITS_URL;

export const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

// Possible application states
export const APPLICATION_STATES = [
  {
    label: i18n`pending`,
    value: 'pending'
  },
  {
    label: i18n`in review`,
    value: 'review'
  },
  {
    label: i18n`qualified`,
    value: 'qualified'
  },
  {
    label: i18n`unqualified`,
    value: 'unqualified'
  }
];

export const REPORTS_PERIOD_FILTER = [
  {
    label: i18n`1 week`,
    value: 'one_week'
  },
  {
    label: i18n`3 weeks`,
    value: 'three_weeks'
  },
  {
    label: i18n`1 month`,
    value: 'one_month'
  },
  {
    label: i18n`6 months`,
    value: 'six_months'
  }
];

// Flags
export const EXPERIMENTAL = process.env.EXPERIMENTAL || false;
export const INITIAL_STATE_ONLY = process.env.INITIAL_STATE_ONLY || false;
