import i18n from 'i18n-literally';
import db from 'i18n.db.json';

// Defaults
i18n.locale = getLocale();
i18n.db = db || {};

export const DEFAULT_LOCALE = 'en';
export const SUPPORTED_LOCALES = [
  {
    label: i18n`English`,
    value: 'en'
  },
  {
    label: i18n`German`,
    value: 'de'
  }
];

export function getLocale(locale) {
  return (
    localStorage.getItem('locale') ||
    navigator.language.replace(/-.*/, '').toLowerCase() ||
    DEFAULT_LOCALE
  );
}

export function setLocale(locale) {
  i18n.locale = locale;
  localStorage.setItem('locale', locale);
}

export default i18n;
