import React, { Component, Fragment, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Switch, Route, withRouter } from 'react-router-dom';
import * as authService from 'services/auth.service';
import { trackPageView } from 'services/track.service';
import { setSession } from 'actions/auth';
import { fetchUserInfo } from 'actions/user';
import { fetchCompanyInfo } from 'actions/company';
import { fetchBudgetInfo } from 'actions/budget';
import { isAuthenticated } from 'reducers/auth';
import { isAdmin } from 'reducers/user';
import { getRequests, getLocale } from 'reducers/ui';
import ErrorBoundary from 'components/ErrorBoundary';
import PrivateRoute from 'components/PrivateRoute';

// Global routes
import routes from 'routes';

// UI
import GlobalAppStyle from 'components/GlobalAppStyle';
import { GlobalStyle, Flexbox, LoadingBar, LoadingActivity } from '@mindmatch/ui';

// Admin related
const AssignCompany = lazy(() => import('views/admin/AssignCompany'));

class App extends Component {
  fetchAccountData() {
    this.props.fetchUserInfo();
    this.props.fetchCompanyInfo();
    this.props.fetchBudgetInfo();
  }

  componentDidMount() {
    const { isAuthenticated, setSession } = this.props;

    if (isAuthenticated) {
      this.fetchAccountData();
    }

    // Add a callback for Lock's `authenticated` event
    authService.lock.on('authenticated', authResult => {
      setSession({ authResult });
      this.fetchAccountData();
    });

    // Add a callback for Lock's `authorization_error` event
    authService.lock.on('authorization_error', authError => {
      console.log({ authError });
    });

    // Track time since page load
    if (window.performance) {
      // Gets the number of milliseconds since page load
      // (and rounds the result since the value must be an integer).
      const timeSincePageLoad = Math.round(performance.now());
      window.gtag('event', 'timing_complete', {
        name: 'load',
        value: timeSincePageLoad,
        event_category: 'JS Dependencies'
      });
    }
  }

  componentDidUpdate(prevProps) {
    // Track all page views
    if (this.props.location.pathname !== prevProps.location.pathname) {
      trackPageView();
    }
  }

  render() {
    const { globalPending, pending, isAuthenticated, isAdmin, locale } = this.props;
    return (
      <Fragment>
        <Helmet
          htmlAttributes={{ lang: locale }}
          defaultTitle="Bringing performance marketing to recruitment through Artificial Intelligence"
          titleTemplate="%s · MindMatch Expand"
        />

        <GlobalStyle />
        <GlobalAppStyle />

        {globalPending && <LoadingBar />}

        <ErrorBoundary>
          {pending ? (
            <Flexbox minHeight="100vh" alignItems="center" justifyContent="center">
              <LoadingActivity />
            </Flexbox>
          ) : (
            <Fragment>
              {/* Public & private routes */}
              <Switch>
                {routes.map(({ path, component, publicly, exact }) =>
                  publicly ? (
                    <Route path={path} exact={exact} component={component} key={path} />
                  ) : (
                    <PrivateRoute path={path} exact={exact} component={component} key={path} />
                  )
                )}
              </Switch>

              {/* Global */}
              <Route render={() => (
                isAuthenticated && isAdmin
                ? <Suspense fallback={<LoadingBar />}><AssignCompany /></Suspense>
                : null
              )} />
            </Fragment>
          )}
        </ErrorBoundary>
      </Fragment>
    );
  }
}

App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  pending: PropTypes.bool.isRequired,
  fetchUserInfo: PropTypes.func.isRequired,
  setSession: PropTypes.func.isRequired,
  fetchCompanyInfo: PropTypes.func.isRequired,
  fetchBudgetInfo: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticated(state),
  isAdmin: isAdmin(state),
  locale: getLocale(state),
  pending: getRequests(state, 'userinfo') > 0 && getRequests(state, 'company') > 0,
  globalPending: getRequests(state) > 0
});

export default withRouter(
  connect(
    mapStateToProps,
    { fetchUserInfo, setSession, fetchCompanyInfo, fetchBudgetInfo }
  )(App)
);
