import React, { Fragment, Suspense } from 'react';
import { LayoutContainer, LoadingBar } from '@mindmatch/ui';
import AppHeader from 'components/AppHeader';
import AppFooter from 'components/AppFooter';
import GlobalNotifications from 'components/GlobalNotifications';

const DefaultLayout = ({ component: Component, ...rest }) => (
  <Fragment>
    <AppHeader />
    <GlobalNotifications />
    <LayoutContainer as="main">
      <Suspense fallback={<LoadingBar />}>{Component ? <Component {...rest} /> : null}</Suspense>
    </LayoutContainer>
    <AppFooter />
  </Fragment>
);

export default DefaultLayout
