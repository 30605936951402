import isFunction from 'lodash.isfunction';
import humps from 'humps';

const analytics = ({ getState }) => next => action => {
  const analytics = action.meta && humps.decamelizeKeys(action.meta.analytics);

  if (!analytics || !isFunction(window.gtag)) {
    return next(action);
  }

  const state = getState();
  const { user } = state;
  const { event_name, ...event_params } = analytics;

  window.gtag('event', event_name, { user_id: user.sub, ...event_params });
  next(action);
};

export default analytics;
