import React from 'react';
import styled from 'styled-components';
import { Flexbox, LayoutContainer } from '@mindmatch/ui';
import i18n from 'services/i18n.service';

const Footer = styled.footer`
  color: #848b98;
  font-size: 12px;
  text-align: center;
  padding: 40px 0;
  height: var(--app-footer-height);

  a,
  span {
    display: inline-block;
    margin: 0 10px;
  }
`;

const AppFooter = () => {
  return (
    <Footer>
      <LayoutContainer>
        <Flexbox justifyContent="space-between">
          <div>
            <span>© {new Date().getFullYear()} MindMatch</span>
            <a href="https://mindmatch.ai/policies/terms">{i18n`Terms`}</a>
            <a href="https://mindmatch.ai/policies/privacy">{i18n`Privacy`}</a>
          </div>
          <div>
            <a href="https://blog.mindmatch.ai">Blog</a>
            <a href="https://mindmatch.ai/about/team">{i18n`About`}</a>
          </div>
        </Flexbox>
      </LayoutContainer>
    </Footer>
  );
};

export default AppFooter;
