import * as actions from 'action-types';

export const setBudget = payload => ({
  type: actions.SET_BUDGET,
  payload
});

export const fetchBudgetInfo = () => ({
  type: actions.API,
  payload: {
    url: '/budget',
    success: budget => setBudget(budget),
    label: 'budget'
  }
});
