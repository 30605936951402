import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import * as actions from 'action-types';
import { AUTH0_CUSTOM_CLAIMS_NAMESPACE } from '../constants';

const initialState = Immutable({});

export default handleActions(
  {
    [actions.SET_USER]: (user, { payload }) => user.merge(Immutable(payload))
  },
  initialState
);

export const getUser = state => state.user.asMutable();
export const getUserRoles = state => state.user[`${AUTH0_CUSTOM_CLAIMS_NAMESPACE}/roles`] || [];
export const isUser = state => getUserRoles(state).includes('user') || false;
export const isAdmin = state => getUserRoles(state).includes('admin') || false;
