import { combineReducers } from 'redux';
import admin from './admin';
import applications from './applications';
import auth from './auth';
import budget from './budget';
import credits from './credits';
import channels from './channels';
import company from './company';
import jobs from './jobs';
import notifications from './notifications';
import reports from './reports';
import ui from './ui';
import user from './user';

export default combineReducers({
  admin,
  applications,
  auth,
  budget,
  credits,
  channels,
  company,
  jobs,
  notifications,
  reports,
  ui,
  user
});
