import React, { Suspense, lazy } from 'react';
import { APPLICATION_STATES } from './constants';
import DefaultLayout from 'views/layouts/DefaultLayout';
import AdminLayout from 'views/layouts/AdminLayout';
import { Flexbox, LoadingActivity } from '@mindmatch/ui';

const Applications = lazy(() => import('views/Applications'));
const ApplicationDetails = lazy(() => import('views/ApplicationDetails'));
const Company = lazy(() => import('views/Company'));
const Dashboard = lazy(() => import('views/Dashboard'));
const Jobs = lazy(() => import('views/Jobs'));
const JobDetails = lazy(() => import('views/JobDetails'));
const JobSettings = lazy(() => import('views/JobSettings'));
const JobForm = lazy(() => import('views/JobForm'));
const Login = lazy(() => import('views/Login'));
const CreditsForm = lazy(() => import('views/CreditsForm'));
const CreditsHistory = lazy(() => import('views/CreditsHistory'));
const Reports = lazy(() => import('views/Reports'));

// Admin
const AdminUsers = lazy(() => import('views/admin/Users'));

const JobListingRedirect = props => {
  const id = props.match.params.id;
  const params = props.location.search;
  window.location.href = `https://jobs.mindmatch.ai/${id}${params}`;
  return null;
};

const routes = [
  // public
  {
    path: '/login',
    publicly: true,
    component: () => (
      <Suspense
        fallback={
          <Flexbox flex="1" justifyContent="center">
            <LoadingActivity />
          </Flexbox>
        }
      >
        <Login />
      </Suspense>
    )
  },
  {
    path: '/job-listings/:id',
    exact: true,
    publicly: true,
    component: JobListingRedirect
  },

  // private
  {
    path: '/',
    exact: true,
    component: () => <DefaultLayout component={Dashboard} />
  },
  {
    path: '/jobs',
    exact: true,
    component: () => <DefaultLayout component={Jobs} />
  },
  {
    path: '/jobs/:id(\\d+)',
    exact: true,
    component: () => <DefaultLayout component={JobDetails} />
  },
  {
    path: '/jobs/:id(\\d+)/settings',
    exact: true,
    component: () => <DefaultLayout component={JobSettings} />
  },
  {
    path: '/jobs/new',
    exact: true,
    component: () => <DefaultLayout component={JobForm} />
  },
  {
    path: '/credits/buy',
    exact: true,
    component: () => <DefaultLayout component={CreditsForm} />
  },
  {
    path: '/credits/history',
    exact: true,
    component: () => <DefaultLayout component={CreditsHistory} />
  },
  {
    path: `/applications/:status(\\${APPLICATION_STATES.map(state => state.value).join('|')})?`,
    exact: true,
    component: () => <DefaultLayout component={Applications} />
  },
  {
    path: '/applications/:id(\\d+)',
    exact: true,
    component: () => <DefaultLayout component={ApplicationDetails} />
  },
  {
    path: '/reports',
    exact: true,
    component: () => <DefaultLayout component={Reports} />
  },
  {
    path: '/company/profile',
    exact: true,
    component: () => <DefaultLayout component={Company} />
  },


  // admin
  {
    path: '/admin',
    component: () => <AdminLayout component={AdminUsers} />
  },
];

export default routes;
