import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from 'reducers/auth';
import { getUser, isUser } from 'reducers/user';
import { INITIAL_STATE_ONLY } from '../constants';
import VerifyWall from 'views/VerifyWall';

const VerifyCheck = ({ component: Component, ...rest }) => {
  // Make sure to render the <VerifyWall /> only if we have a user object (not empty).
  if (Object.keys(rest.user).length === 0 && rest.user.constructor === Object) return null;

  return rest.isVerified || INITIAL_STATE_ONLY ? ( // To test the application with "empty state"
    <Component {...rest} />
  ) : (
    <VerifyWall />
  );
};

const PrivateRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return rest.isAuthenticated ? (
          <VerifyCheck component={component} {...rest} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isVerified: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticated(state),
  isVerified: isUser(state),
  user: getUser(state)
});

export default connect(mapStateToProps)(PrivateRoute);
