import * as actions from 'action-types';
import * as authService from 'services/auth.service';

export const setSession = ({ authResult }) => dispatch => {
  if (authResult) {
    authService.setSession(authResult);

    dispatch({
      type: actions.SET_SESSION,
      payload: {
        isAuthenticated: true
      },
      meta: {
        analytics: {
          eventName: 'login',
          eventLabel: `${authResult.idTokenPayload.sub.split('|')[0]}`
        }
      }
    });
  }

  // TODO: handle error (undefined authResult)
};

export const resetSession = () => dispatch => {
  authService.logout();

  dispatch({
    type: actions.RESET_SESSION,
    analytics: {
      eventName: 'logout'
    }
  });
};
