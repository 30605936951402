import * as actions from 'action-types';
import { AUTH0_DOMAIN } from '../constants';

export const setUser = payload => ({
  type: actions.SET_USER,
  payload
});

export const fetchUserInfo = () => ({
  type: actions.API,
  payload: {
    url: `https://${AUTH0_DOMAIN}/userinfo`,
    success(userinfo) {
      return setUser(userinfo);
    },
    label: 'userinfo',
    accessToken: localStorage.getItem('access_token')
  }
});
