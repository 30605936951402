import Auth0Lock from 'auth0-lock';
import { getLocale } from 'services/i18n.service';
import {
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH0_CALLBACK_URL,
  AUTH0_CONTAINER_ID
} from '../constants';

// Configure Auth0 lock
export const lock = new Auth0Lock(AUTH0_CLIENT_ID, AUTH0_DOMAIN, {
  container: AUTH0_CONTAINER_ID,
  auth: {
    redirectUrl: AUTH0_CALLBACK_URL,
    responseType: 'token id_token',
    params: {
      scope: 'openid profile email'
    }
  },
  theme: {
    logo: 'https://cityflamingo.mindmatch.ai/images/mindmatch-mark-320.png',
    primaryColor: '#0060ea'
  },
  language: getLocale(),
  languageDictionary: {
    title: 'MindMatch'
  },
  // Mandatory for custom domains
  configurationBaseUrl: 'https://cdn.eu.auth0.com'
});

export const isAuthenticated = () => {
  // Check whether the current time is past the
  // access token's expiry time
  let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
  return Date.now() < expiresAt;
};

export const login = () => {
  // Call the show method to display the widget.
  lock.show();
};

export const logout = () => {
  // Clear access token and ID token from local storage
  localStorage.removeItem('access_token');
  localStorage.removeItem('id_token');
  localStorage.removeItem('expires_at');

  lock.logout({
    returnTo: window.location.origin
  });
};

export const setSession = authResult => {
  if (authResult && authResult.accessToken && authResult.idToken) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(authResult.expiresIn * 1000 + Date.now());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
  }
};

export const renewToken = () => {
  // Silent authentication request
  lock.checkSession({}, (error, authResult) => {
    if (error) return console.log(error);
    setSession(authResult);
  });
};
