import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import * as actions from 'action-types';
import * as authService from 'services/auth.service';

const initialState = Immutable({
  isAuthenticated: authService.isAuthenticated()
});

export default handleActions(
  {
    [actions.SET_SESSION]: (auth, { payload }) => auth.merge(Immutable(payload))
  },
  initialState
);

export const isAuthenticated = state => state.auth.getIn(['isAuthenticated']) || false;
