import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import * as actions from 'action-types';

const initialState = Immutable({
  currentBudget: null,
  exhausted: false,
  topedUpAt: null
});

export default handleActions(
  {
    [actions.SET_BUDGET]: (budget, { payload }) => Immutable(payload)
  },
  initialState
);
