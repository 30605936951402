import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import * as actions from 'action-types';
import { sortByKey } from '../utils';

const initialState = Immutable({
  companies: [],
  users: []
});

export default handleActions(
  {
    [actions.ADMIN_SET_COMPANIES]: (admin, { payload }) => Immutable.merge(admin, { companies: payload }),
    [actions.ADMIN_SET_USERS]: (admin, { payload }) => Immutable.merge(admin, { users: payload })
  },
  initialState
);

export const getCompanies = state => {
  const companies = Immutable.getIn(state, ['admin', 'companies']).asMutable() || [];

  // TODO: Sorting should be handled by the backend.
  const companiesSorted = companies.sort(sortByKey('name'));
  return companiesSorted;
};

export const getUsers = state => {
  const users = Immutable.getIn(state, ['admin', 'users']).asMutable() || [];

  // TODO: Sort/group them by e.g no comany assigned
  const usersSorted = users.sort((a, b) => a.id - b.id).reverse();
  return usersSorted;
};
