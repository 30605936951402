import { createGlobalStyle } from 'styled-components';

const GlobalAppStyle = createGlobalStyle`
  html {
    --app-header-height: 70px;
    --app-footer-height: 100px;

    background: white;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  main {
    flex: 1;
  }
`;

export default GlobalAppStyle;
