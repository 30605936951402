import React, { Fragment, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isAdmin } from 'reducers/user';
import { LayoutContainer, LoadingBar } from '@mindmatch/ui';
import AppHeader from 'components/AppHeader';
import AppFooter from 'components/AppFooter';
import GlobalNotifications from 'components/GlobalNotifications';

const AdminLayout = ({ isAdmin, component: Component, ...rest }) => {
  if (!isAdmin) return 'Sorry, you don’t have permission to see that.'

  return (
    <Fragment>
      <AppHeader context="admin" />
      <GlobalNotifications />
      <LayoutContainer as="main">
        <Suspense fallback={<LoadingBar />}>{Component ? <Component {...rest} /> : null}</Suspense>
      </LayoutContainer>
      <AppFooter />
    </Fragment>
  );
};

AdminLayout.propTypes = {
  isAdmin: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isAdmin: isAdmin(state)
});

export default connect(mapStateToProps)(AdminLayout);
