import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resetSession } from 'actions/auth';
import { getUser } from 'reducers/user';
import i18n from 'services/i18n.service';
import { Flexbox, Button, Box, Icon, Tooltipped } from '@mindmatch/ui';

const VerifyWall = ({ user, resetSession }) => {
  return (
    <Flexbox minHeight="100vh" alignItems="center" justifyContent="center">
      <Box>
        <Box.Row style={{ textAlign: 'center' }}>
          <img
            width="58"
            height="58"
            src="https://cityflamingo.mindmatch.ai/images/mindmatch-mark-320.png"
            alt=""
          />
          <h1>{user.givenName ? `Welcome ${user.givenName}` : 'Welcome!'}</h1>
          <p>{i18n`Your account setup is almost done.`}</p>
        </Box.Row>

        {user.emailVerified ? (
          <Box.Row as={Flexbox} justifyContent="space-between" alignItems="center">
            <div>
              <b>{user.email}</b>
            </div>
            <Tooltipped content={i18n`E-Mail verified`}>
              <Icon name="check" color="var(--text-green)" size={24} />
            </Tooltipped>
          </Box.Row>
        ) : (
          <Box.Row>
            <p>{i18n`We sent an email to ${user.email}.`}</p>
            <p>{i18n`Please verify your account by following the instructions in the email.`}</p>
          </Box.Row>
        )}

        {user.verified ? (
          <Box.Row as={Flexbox} justifyContent="space-between" alignItems="center">
            <div>{i18n`Company`}</div>
            <Tooltipped content={i18n`Company verified`}>
              <Icon name="check" color="var(--text-green)" size={24} />
            </Tooltipped>
          </Box.Row>
        ) : (
          <Box.Row>
            <p>{i18n`We will reach out to you via email to complete your account setup.`}</p>
          </Box.Row>
        )}

        <Box.Row style={{ fontSize: 'var(--font-size-small)', textAlign: 'center' }}>
          <Button variant="link" onClick={resetSession}>
            {i18n`Sign out`}
          </Button>
        </Box.Row>
      </Box>
    </Flexbox>
  );
};

VerifyWall.propTypes = {
  user: PropTypes.object.isRequired,
  resetSession: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: getUser(state),
  resetSession: resetSession(state)
});

export default connect(
  mapStateToProps,
  { resetSession }
)(VerifyWall);
