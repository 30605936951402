import * as actions from 'action-types';
import { setLocale as setCurrentLocale } from 'services/i18n.service';

export const startNetwork = (payload = 'global') => ({
  type: actions.START_NETWORK,
  payload
});

export const endNetwork = (payload = 'global') => ({
  type: actions.END_NETWORK,
  payload
});

export const setLocale = (payload = 'en') => dispatch => {
  setCurrentLocale(payload);

  // Quick fix to update constants (including i18n tags) not part of VDOM.
  window.location.reload();
  return;

  // dispatch({
  //   type: actions.SET_LOCALE,
  //   payload
  // });
};
