import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import * as actions from 'action-types';

const initialState = Immutable([]);

export default handleActions(
  {
    [actions.SET_CREDITS]: (credits, { payload }) => Immutable(payload)
  },
  initialState
);
