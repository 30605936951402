import humps from 'humps';
import * as actions from 'action-types';
import { renewToken } from 'services/auth.service';
import { startNetwork, endNetwork } from 'actions/ui';
import { pushNotification } from 'actions/notifications';
import { INITIAL_STATE_ONLY, API_BASE_URL } from '../constants';
import { removeFalsyValues } from 'utils';

const ONE_HOUR = 3600000;
const RETRIES_LIMIT = 3;

const api = ({ dispatch, getState }) => next => action => {
  if (action.type !== actions.API || action.retries > RETRIES_LIMIT || INITIAL_STATE_ONLY) {
    return next(action);
  }

  const {
    url,
    method = 'GET',
    headers = {},
    body,
    params = {},
    success,
    label,
    accessToken = localStorage.getItem('id_token')
  } = action.payload;

  const { ui: { locale }} = getState();
  const cleanedParams = humps.decamelizeKeys(removeFalsyValues(params));
  const queryStringParams = new URLSearchParams({ locale, ...cleanedParams }).toString();
  const requestURL = url.startsWith('http') ? url : `${API_BASE_URL}${url}${queryStringParams && `?${queryStringParams}`}`;
  const requestOptions = {
    method,
    headers: removeFalsyValues({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      ...headers
    }),
    body
  };

  // Anticipate expiration of the access token.
  if (localStorage.getItem('expires_at') - Date.now() < ONE_HOUR) {
    renewToken();
  }

  dispatch(startNetwork(label));
  fetch(requestURL, requestOptions)
    .then(handleErrors)
    .then(readData)
    .then(data => humps.camelizeKeys(data))
    .then(data => {
      dispatch(success(data));
      dispatch(endNetwork(label));
    })
    .catch(error => {
      dispatch(endNetwork(label));

      // TODO: report error
      console.error(error);

      if (error.response && error.response.status === 401) {
        // Try to renew access token
        renewToken();

        // Re-dispatch action with a delay to limit retries
        // in case the silent authentication did not finish yet.
        window.setTimeout(() => {
          dispatch({ ...action, retries: action.retries + 1 || 1 });
        }, 500);
      } else {
        dispatch(
          pushNotification({
            body: 'There was an error. Please try again.'
          })
        );
      }
    });
};

function handleErrors(response) {
  if (!response.ok) {
    const error = new Error(response.statusText || response.status);
    error.response = response;
    return Promise.reject(error);
  }

  return response;
}

// Read JSON with Text Fallback
// https://stevenklambert.com/writing/fetch-json-text-fallback/
function readData(response) {
  return response.clone().json().catch(() => response.text());
}

export default api;
