import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import * as actions from 'action-types';

const initialState = Immutable([]);

export default handleActions(
  {
    [actions.SET_CHANNELS]: (channels, { payload }) => Immutable(payload),
    [actions.UPDATE_CHANNEL]: (channels, { id, payload }) => {
      return channels.map(channel => (channel.id === id ? { ...channel, ...payload } : channel));
    }
  },
  initialState
);
