import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { resetSession } from 'actions/auth';
import { setLocale } from 'actions/ui';
import { getLocale } from 'reducers/ui';
import { getUser, isAdmin } from 'reducers/user';
import { getCompany } from 'reducers/company';
import i18n, { SUPPORTED_LOCALES } from 'services/i18n.service';
import { Dropdown, Button } from '@mindmatch/ui';

const UserMenu = ({ context, isAdmin, locale, user, company, resetSession, setLocale }) => {
  return (
    <Dropdown>
      <Button variant="link" as="summary" style={{ verticalAlign: 'initial' }}>
        <img
          src={`https://ui-avatars.com/api/?name=${user.nickname}&background=f3f5f8&color=6C7280&font-size=0.38&size=60&rounded=true`}
          alt={user.name}
          width="35"
          height="35"
          style={{ transform: 'translateY(12px)', marginRight: '8px', marginTop: '-20px' }}
        />
        {company.name}
        <Dropdown.Arrow />
      </Button>
      <Dropdown.Menu>
        <ul>
          <li>
            <Dropdown.Item>
              {i18n`Signed in as`}
              <b
                style={{
                  display: 'block',
                  maxWidth: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {user.email}
              </b>
            </Dropdown.Item>
          </li>
          {isAdmin && (
            <Fragment>
              <Dropdown.MenuDivider />
              <li>
                {context === 'admin'
                  ? <Dropdown.Item as={Link} to="/">{i18n`Exit admin`}</Dropdown.Item>
                  : <Dropdown.Item as={Link} to="/admin">Admin</Dropdown.Item>
                }
              </li>
            </Fragment>
          )}
          <Dropdown.MenuDivider />
          <li>
            <Dropdown.Item as={Link} to="/company/profile">
              {i18n`Company Profile`}
            </Dropdown.Item>
          </li>
          <li>
            <Dropdown.Item as={Link} to="/jobs">
              {i18n`My jobs`}
            </Dropdown.Item>
          </li>
          <Dropdown.MenuDivider />
          <li>
            <Dropdown.Item style={{ backgroundColor: 'white' }}>
              <span
                style={{
                  color: 'var(--text-gray)',
                  display: 'inline-block',
                  marginBottom: '5px'
                }}
              >{i18n`Language`}</span>
              <br />
              {SUPPORTED_LOCALES.map((locale, idx) => (
                <Button
                  variant="link"
                  onClick={() => setLocale(locale.value)}
                  key={idx}
                  style={{ display: 'block', marginLeft: '0' }}
                >
                  {locale.label}
                </Button>
              ))}
            </Dropdown.Item>
          </li>
          <Dropdown.MenuDivider />
          <li>
            <Button as={Dropdown.Item} variant="link" onClick={resetSession}>
              {i18n`Sign out`}
            </Button>
          </li>
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  )
}

UserMenu.propTypes = {
  context: PropTypes.oneOf(['admin']),
  isAdmin: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  resetSession: PropTypes.func.isRequired,
  setLocale: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isAdmin: isAdmin(state),
  locale: getLocale(state),
  user: getUser(state),
  company: getCompany(state)
});

export default connect(
  mapStateToProps,
  { resetSession, setLocale }
)(UserMenu);
