export function sortByKey(key) {
  return (a, b) => {
    const x = `${a[key]}`.toLowerCase();
    const y = `${b[key]}`.toLowerCase();
    let comparison = 0;

    if (x > y) {
      comparison = 1;
    } else if (x < y) {
      comparison = -1;
    }
    return comparison;
  };
}

// Remove Falsy values or empty strings
export const removeFalsyValues = obj => Object.keys(obj).reduce((newObj, key) => {
  if (obj[key]) {
    newObj[key] = obj[key];
  }
  return newObj;
}, {});
