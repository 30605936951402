export const API = 'API';
export const START_NETWORK = 'START_NETWORK';
export const END_NETWORK = 'END_NETWORK';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_SESSION = 'SET_SESSION';
export const RESET_SESSION = 'RESET_SESSION';
export const SET_USER = 'SET_USER';
export const SET_JOBS = 'SET_JOBS';
export const UPDATE_JOB = 'UPDATE_JOB';
export const SET_CHANNELS = 'SET_CHANNELS';
export const UPDATE_CHANNEL = 'UPDATE_CHANNEL';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_BUDGET = 'SET_BUDGET';
export const SET_CREDITS = 'SET_CREDITS';
export const SET_REPORTS = 'SET_REPORTS';
export const SET_APPLICATIONS = 'SET_APPLICATIONS';
export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';
export const DELETE_APPLICATION = 'DELETE_APPLICATION';
export const TRACK_EVENT = 'TRACK_EVENT';
export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const ADMIN_SET_COMPANIES = 'ADMIN_SET_COMPANIES';
export const ADMIN_SET_USERS = 'ADMIN_SET_USERS';
export const ADMIN_UPDATE_USER = 'ADMIN_UPDATE_USER';
