import React from 'react';

const LogoMark = ({ size = 40, ...props }) => (
  <svg viewBox="29.7 95 45 48" width={size} height={size} role="img" {...props}>
    <title>MindMatch</title>
    <path
      d="M37.27 125.351L48.231 104.3c.34-.656.798-1.152 1.374-1.49.576-.337 1.232-.506 1.969-.506h1.302c.737 0 1.393.17 1.969.506.576.338 1.034.834 1.374 1.49l10.89 19.7c2.317 4.192.226 15.03-.085 15.01a2.81 2.81 0 0 1-.836-.168 1.903 1.903 0 0 1-.665-.435c-.198-.197-.392-.474-.58-.83l-10.623-20.77a40.634 40.634 0 0 1-1.09-2.22 53.879 53.879 0 0 1-1.006-2.361 56.35 56.35 0 0 1-1.005 2.417 27.8 27.8 0 0 1-1.12 2.248l-10.59 20.686c-.189.356-.382.633-.58.83-.199.196-.42.341-.666.435a2.81 2.81 0 0 1-.836.169c-.311.019-.08-14.315-.156-13.66z"
      fill="#0043A5"
    />
    <path
      fill="#1f2228"
      d="M49.47 118.068l-.473.936-4.409-7.799 1.103-1.871zm5.51-.156l.63 1.247 4.409-7.798-1.102-2.027zm-17.71 7.439l.992-1.98s.126 15.423 0 15.471a2.81 2.81 0 0 1-.836.169c-.311.019-.08-14.315-.156-13.66zm29.78-1.115l-.991-1.957s-.126 16.474 0 16.522c.245.092.524.148.835.166.312.019.08-15.379.156-14.73z"
    />
    <path
      fill="#0060EA"
      d="M72.617 98.032a2.798 2.798 0 0 1 2.805 2.799v35.353a2.794 2.794 0 0 1-2.799 2.799h-5.642V115.43c0-.543.015-1.134.043-1.77.028-.638.08-1.284.156-1.94l-10.961 21.05c-.34.656-.798 1.153-1.374 1.49-.576.337-1.232.506-1.969.506h-1.302c-.737 0-1.393-.169-1.969-.506-.576-.337-1.034-.834-1.374-1.49l-10.96-21.106c.056.674.103 1.335.141 1.981.038.647.057 1.242.057 1.785v23.553h-5.642a2.795 2.795 0 0 1-2.799-2.799v-35.353a2.802 2.802 0 0 1 2.805-2.799h4.503c.415 0 .779.01 1.09.028.312.02.59.075.836.17.245.093.467.238.665.435.199.196.392.473.58.829l10.622 20.77c.378.712.741 1.452 1.09 2.22.35.769.685 1.556 1.006 2.361a56.35 56.35 0 0 1 1.006-2.417 27.8 27.8 0 0 1 1.118-2.248l10.593-20.686c.189-.356.383-.633.58-.83.2-.196.42-.341.666-.435a2.81 2.81 0 0 1 .836-.169c.311-.018.675-.028 1.09-.028h4.503z"
    />
  </svg>
);

export default LogoMark;
