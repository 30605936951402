import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import * as actions from 'action-types';

const initialState = Immutable([]);

export default handleActions(
  {
    [actions.SET_APPLICATIONS]: (applicants, { payload }) => Immutable(payload),
    [actions.UPDATE_APPLICATION]: (applications, { id, payload }) => {
      return applications.map(application =>
        application.id === id ? { ...application, ...payload } : application
      );
    },
    [actions.DELETE_APPLICATION]: (applications, { id }) => [
      ...applications.slice(0, id),
      ...applications.slice(id + 1)
    ]
  },
  initialState
);
