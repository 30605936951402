import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import * as actions from 'action-types';

const initialState = Immutable({});

export default handleActions(
  {
    [actions.SET_REPORTS]: (reports, { payload }) => Immutable(payload)
  },
  initialState
);
