import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import * as actions from 'action-types';

const initialState = Immutable([]);

export default handleActions(
  {
    [actions.PUSH_NOTIFICATION]: (notifications, { payload }) => {
      return [payload].concat(Immutable.asMutable(notifications));
    },
    [actions.REMOVE_NOTIFICATION]: (notifications, { payload }) => {
      const { id } = payload;
      return notifications.filter(notification => notification.id !== id);
    }
  },
  initialState
);

export const getNotifications = state => state.notifications;
