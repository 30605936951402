import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getNotifications } from 'reducers/notifications';
import { removeNotification } from 'actions/notifications';
import { LayoutContainer, Flexbox, Notification, Icon, Button } from '@mindmatch/ui';

const GlobalNotifications = ({ notifications, removeNotification }) => (
  notifications.map(({ id, body }, idx) => (
    <Notification key={idx}>
      <LayoutContainer>
        <Flexbox justifyContent="space-between">
          <div>{body}</div>
          <Button variant="link" onClick={() => removeNotification({ id })}>
            <Icon name="close" />
          </Button>
        </Flexbox>
      </LayoutContainer>
    </Notification>
  ))
);

GlobalNotifications.propTypes = {
  notifications: PropTypes.array.isRequired,
  removeNotification: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  notifications: getNotifications(state)
});

export default connect(mapStateToProps, { removeNotification })(GlobalNotifications);
