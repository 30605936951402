import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import * as actions from 'action-types';

const initialState = Immutable([]);

export default handleActions(
  {
    [actions.SET_JOBS]: (jobs, { payload }) => Immutable(payload),
    [actions.UPDATE_JOB]: (jobs, { id, payload }) => {
      return jobs.map(job => (job.id === id ? { ...job, ...payload } : job));
    }
  },
  initialState
);
