import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import * as actions from 'action-types';
import { getLocale as currentLocale } from 'services/i18n.service';

const initialState = Immutable({
  locale: currentLocale(),
  requests: {}
});

export default handleActions(
  {
    [actions.START_NETWORK]: (ui, { payload = 'global' }) =>
      ui.updateIn(['requests', payload], counter => (counter || 0) + 1),
    [actions.END_NETWORK]: (ui, { payload = 'global' }) =>
      ui.updateIn(['requests', payload], counter => (counter || 0) - 1),
    [actions.SET_LOCALE]: (ui, { payload = 'en' }) => Immutable.set(ui, 'locale', payload)
  },
  initialState
);

export const getRequests = (state, label = 'global') => {
  let requests = null;

  if (label === 'global') {
    // total requests
    requests = Object.values(state.ui.requests.asMutable()).reduce((a, b) => a + b, 0);
  } else {
    // requests by label
    requests = state.ui.getIn(['requests', label]);
  }

  return requests || 0;
};

export const getLocale = state => state.ui.locale;
