import * as actions from 'action-types';

export const pushNotification = (
  payload = { body: null, level: null, autoHide: false }
) => dispatch => {
  const id = [...Array(5)].map(i => (~~(Math.random() * 36)).toString(36)).join('');
  const { autoHide } = payload;

  dispatch({
    type: actions.PUSH_NOTIFICATION,
    payload: { ...payload, id },
    meta: {
      analytics: {
        eventName: 'show notification',
        eventCategory: 'notification'
      }
    }
  });

  if (autoHide) {
    window.setTimeout(() => {
      dispatch({
        type: actions.REMOVE_NOTIFICATION,
        payload: { id }
      });
    }, 5000);
  }
};

export const removeNotification = payload => ({
  type: actions.REMOVE_NOTIFICATION,
  payload
});
