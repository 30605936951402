import humps from 'humps';
import * as actions from 'action-types';

export const setCompany = payload => ({
  type: actions.SET_COMPANY,
  payload
});

export const fetchCompanyInfo = () => ({
  type: actions.API,
  payload: {
    url: '/company',
    success: company => setCompany(company),
    label: 'company'
  }
});

export const patchCompanyInfo = payload => ({
  type: actions.API,
  payload: {
    url: `/company`,
    method: 'PATCH',
    body: JSON.stringify({ company: humps.decamelizeKeys(payload) }),
    success: company => setCompany(company),
    label: 'patch company info'
  },
  meta: {
    throttle: 1000
  }
});
