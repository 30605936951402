import React, { Component } from 'react';
import { Flexbox, Box, Button } from '@mindmatch/ui';
import i18n from 'services/i18n.service';

class ErrorBoundary extends Component {
  state = {
    hasError: false
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Flexbox minHeight="100vh" alignItems="center" justifyContent="center">
          <Box>
            <Box.Body>
              <h4>{i18n`Something went wrong...`}</h4>
              <p>
                <Button variant="link" onClick={() => window.location.reload(true)}>
                  {i18n`Go back`}
                </Button>
              </p>
            </Box.Body>
          </Box>
        </Flexbox>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
