import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import { isAuthenticated } from 'reducers/auth';
import { breakpoint, LayoutContainer } from '@mindmatch/ui';
import i18n from 'services/i18n.service';
import LogoMark from './LogoMark';
import UserMenu from './UserMenu';

const Header = styled.header`
  background-color: var(--bg-white);
  border-bottom: 1px solid var(--border-gray);

  ${breakpoint.medium`
    position: sticky;
    top: 0;
    z-index: 1;
  `};
`;

Header.FlexContainer = styled(LayoutContainer)`
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  margin-top: 10px;

  ${breakpoint.medium`
    margin-top: 0;
    align-items: center;
    justify-content: space-between;
    height: var(--app-header-height);
  `};
`;

Header.Brand = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  ${breakpoint.medium`
    flex: initial;
    border-right: 1px solid var(--border-gray);
    height: 100%;
    padding-right: 20px;
  `};

  /* Special media query */
  @media (min-width: ${1140 / 16}em) {
    transform: translateX(calc(-100% + 1px));
  }

  > svg {
    vertical-align: middle;
  }
`;

Header.NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  order: 1;
  flex: 1 0 100%;
  overflow: hidden;
  height: 30px;
  margin: 20px -20px 0 -20px;

  ${breakpoint.medium`
    margin: 0;
    height: auto;
    order: initial;
    flex: initial;
    align-items: center;
    justify-content: flex-start;
  `};
`;

Header.Nav = styled.nav`
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  padding: 0 20px 60px 20px;

  ${breakpoint.medium`
    overflow: initial;
    padding: 0;
  `};
`;

Header.NavItem = styled(NavLink)`
  --spacing: 20px;

  color: #848b98;
  white-space: nowrap;
  padding: 10px 0;
  margin: 10px 0 0 0;

  ${breakpoint.medium`
    padding: 0;
    transition: color 0.1s ease;
    margin: 0 var(--spacing);
  `};

  & + & {
    margin-left: var(--spacing);
  }

  &:hover,
  &[aria-current="page"] {
    color: var(--text-blue);
    text-decoration: none;
  }
`;

Header.Actions = styled.ul`
  --spacing: 5px;

  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${breakpoint.medium`
    /* prevent flickering/jumping */
    min-width: 150px;
  `};
`;

Header.ActionItem = styled.li`
  display: inline-block;

  ${breakpoint.medium`
    padding: 0 var(--spacing);
  `};
`;

const Nav = ({ context }) => {
  switch (context) {
    case 'admin':
      return null;
    default:
      return (
        <Fragment>
          <Header.NavItem to="/" exact>{i18n`Dashboard`}</Header.NavItem>
          <Header.NavItem to="/jobs">Jobs</Header.NavItem>
          <Header.NavItem to="/applications">{i18n`Applications`}</Header.NavItem>
          <Header.NavItem to="/reports">{i18n`Reports`}</Header.NavItem>
        </Fragment>
      );
  }
};

const AppHeader = ({ isAuthenticated, context }) => (
  <Header>
    <Header.FlexContainer>
      <Header.Brand>
        <Link to="/">
          <LogoMark size={30} aria-label="Homepage" />
        </Link>
      </Header.Brand>

      <Header.NavContainer>
        <Header.Nav>
          <Nav context={context} />
        </Header.Nav>
      </Header.NavContainer>

      <Header.Actions>
        <Header.ActionItem>
          <UserMenu context={context} />
        </Header.ActionItem>
      </Header.Actions>
    </Header.FlexContainer>
  </Header>
);

AppHeader.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticated(state)
});

export default connect(mapStateToProps)(AppHeader);
